var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Cadastro de Devices")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.listaItens,"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{
                listaSelect: _vm.item,
                getItens: _vm.getItens,
                jsonData: false,
                switchStatus: _vm.switchStatus,
                activeSwitchStatus: true,
                isStatus: _vm.isStatus,
                switchStatusName: 'Desativados'
              },"labelTextField":'Busca por serial:'},on:{"search":_vm.buscar}})]},proxy:true},{key:"item.deviceTypeId",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.deviceTypeId === 1 ? 'Android' : 'iOS')+" ")]}},{key:"item.deleted",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.deleted),"dark":""}},[_vm._v(_vm._s(_vm.textStatus(item.deleted)))])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(_vm.permiteAcao(_vm.$route, 'edit') && _vm.getStatus(item.deleted))?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","tile":"","outlined":"","color":_vm.variables.colorPrimary},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Editar ")]):_vm._e(),(_vm.permiteAcao(_vm.$route, 'delete') && _vm.getStatus(item.deleted))?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","tile":"","outlined":"","color":_vm.variables.colorError},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" Excluir ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}])})],1)],1),_c('v-btn',{staticClass:"mx-2 fab-cadastrar-usuario",attrs:{"fab":"","color":"primary"},on:{"click":_vm.cadastrar}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }